.SubmitButton{
    display: inline-block;

    width: 200px;
    background-image: linear-gradient(90deg, #BF734F, #F19867, #AF6339);
    text-align: center;
    padding-top: 14px;
    padding-bottom: 16px;
    color: white;
    margin-top: 64px;
    cursor: pointer;
    filter: brightness(1);

    &:hover{
        filter: brightness(1.25);
    }
}