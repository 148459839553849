.FloorPlan{
    position: relative;
    top: $header-height;
    width: 1470px;
    height: 980px;

    .FloorPlanRotateLeft, .FloorPlanRotateRight{
        position: absolute;
        top: 50%;
        transform: translate(0, -50%) scale(0.6);
        cursor: pointer;

        :hover{
            filter: brightness(1.1);
        }

        :active{
            filter: brightness(1.3);
        }
    }

    .FloorPlanRotateLeft{
        left: 30px;
    }

    .FloorPlanRotateRight{
        right: 30px;
    }

    .FloorPlanCoords{
        width: 100%;
        height: $app-height - $header-height;
        position: absolute;

        .FloorPlanCoord{
            display:flex;
            align-items: center;
            justify-content: center;
            color: white;
            position: absolute;
            width: 50px;
            height: 50px;
            cursor: pointer;
            color: $color-background;
            font-size: 18px;

            &[data-no-clicks='true']{
                pointer-events: none;
            }

            filter: brightness(1);

            .FloorPlanThumb{
                object-fit: cover;
                object-position: right top;
                width: 500px;
                height: 500px;
                transform: translate(50%, 50%);
                visibility: hidden;
                pointer-events: none;
            }

            &:hover{
                .FloorPlanIndicator{
                    filter: brightness(1.7);
                }
                .FloorPlanThumb{
                    visibility: visible;
                }
            }
        }
    }
}