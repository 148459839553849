.LoadingIndicator{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .LoadingIndicatorContent{
        width: 100%;
        height: 100%;

        transition: opacity 0.5s;
        opacity: 0;

        /*
        &[data-loading="true"]{
            opacity: 0;
        }
        */

        &[data-loading="false"]{
            opacity: 1;
        }
    }

    .Spinner{
        position: absolute;

        animation: fadeIn ease 1.5s;
        
        &[data-loading="false"]{
            *{
               display:none;
            }
        }
    }

    @keyframes fadeIn {
        0% { opacity:0; }
        75% { opacity:0; }
        100% { opacity:1; }
    }
}

