.Lightbox{
    $lightbox-height: $app-height - $header-height;
    $content-height: $lightbox-height - $header-height;

    user-select: none;
    -webkit-touch-callout: none;

    position: absolute;
    top: $header-height;
    left: 0;
    width: 100%;
    height: $lightbox-height;

    font-size: 16px;

    .LightboxCover{
        position: absolute;
        top: 0;
        left: 0;
        width: $app-width;
        height: $lightbox-height;
        background-color: $color-dark-background;
    }

    .LightboxClose, .LightboxNext, .LightboxPrev{
        &:hover{
            opacity: 0.7;
            cursor: pointer;
        }
    }


    .LightboxClose{
        cursor: pointer;
        padding: 7px;
        position: absolute;
        right: 4px;
        top: 0;
        transform: scale(0.9);
        &:hover{
            opacity: 0.8;
        }
    }

    .LightboxNext{
        position: absolute;
        right: 0;
        top: calc(50% + 2px);
        transform: translate(0, -50%) scale(0.77);
    }

    .LightboxPrev{
        position: absolute;
        left: 0;
        top: calc(50% + 2px);
        transform: translate(0, -50%) scale(0.77);
    }

    .LightboxContent{
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;

        clip: rect(0px, $app-width, $lightbox-height, 0px);

        .LightboxItem{
            //transition: transform 0.5s;
            min-width: $app-width;
            width: $app-width;
            display: flex;
            flex-direction: column;

            .LightboxItemImageContainer{
                width: $app-width;
                height: $app-height;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                img{
                    max-width: $app-width;
                    max-height: $content-height;
                }
            }

            .LightboxCaptions{
                height: $header_height;
                max-width: $app-width - 200px; 
                display: flex;
                flex-direction: row;
                justify-content: space-around;

                &[data-spread="true"]{
                    justify-content: space-between;
                    .LightboxItemCaption{
                        top: -30px !important;
                    }
                }

                .LightboxItemCaption{
                    position: relative;
                    top: -6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: $header_height;
                    color: white;
                    font-weight: 400;
                    letter-spacing: 0.01em;
                    font-size: 22px;
                }
            }
        }

    }
}