*[data-hide="true"]{
    display: none !important;
}

*[data-invisible="true"]{
    visibility: hidden !important;
}

*[data-fade="true"]{
    opacity: 1;
    transition: opacity 0.3s;
}


*[data-fade="false"]{
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
}


*[data-transition="false"]{
    transition: none !important;
}

@mixin no-select{
    *{
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        outline: 0;
    }  
}