.dots{
    position: absolute;
    top: 92%;
    left: 65%;


    .dot{
        display:inline-block;
        width: 14px;
        height: 14px;
        border: 2px solid rgba(255, 255, 255, 0.6);
        border-radius: 50%;
        margin: 4px;

        &[data-selected="true"]{
            background-color: white;
            border-color: white;
        }
    }

}