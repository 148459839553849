.SlideReveal{
    cursor: pointer;

    .SlideRevealChild{
        position: absolute;
        //transform: translate(-50%, -50%);
    }

    .SliderRevealTouchZone{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: green;
        opacity: 0;
    }

    .SlideRevealCover{
        position: absolute;

        .SliderRevealIndicator{
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #C0764C;
        }

        .SliderRevealIndicatorIcon{
            position: absolute;
            pointer-events: none;

            img{
                transform: translate(-50%, -50%) scale(0.8);
            }
        }
    }
    
}