.FloorplanKey{
    img{
        display: block;
        margin:auto;
        margin-right: 0;
    }

    img:first-child{
        margin-top: 22px;
    }

    img:last-child{
        margin-top: 22px;    
    }

    text-align: right;

    position: absolute;
    right: 20px;
    bottom: 30px;
}