body {
	margin: 0;
    padding: 0;
    overflow: hidden;
    //background-color: rgb(50, 50, 50);
    background-color: $color-dark-background;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    overscroll-behavior-y: none;
    position: fixed;
    width: 1920px;
    height: 1080px;
    max-width: 1920px;
    max-height: 1080px;
    -webkit-text-size-adjust: none;
    touch-action: pan-x pan-y;
}

*{
    box-sizing: border-box;
}

*{
    -webkit-tap-highlight-color: transparent;
    outline: 0;  
}

*:not(input){
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}