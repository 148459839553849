.ImageSelector{

    .ImageSelectorOption{
        position: absolute;
        opacity: 1;
        transition: opacity 0.3s;

        &[data-hide="true"]{
            opacity: 0;
            display: block !important;
        }
    }


}