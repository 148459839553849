.ThumbnailPicker{
    position:absolute;

    &[data-debug='true']{
        .ThumbnailPickerItem{
            background-color: red;
            opacity: 0.5;
        }
    }

    .ThumbnailPickerItem{
        position: absolute;
        background-color: white;
        opacity: 0.0;
        
        cursor: pointer;
        transition: opacity 0.6s;
       
        &:hover{
            opacity: 0.4;
            transition: opacity 0.2s;
        }
    }

}