.Button{
    font-size: 100%;
    font-family: inherit;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover{
        opacity: 0.7;
    }
}