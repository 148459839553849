.fade-between > * {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s;

    &[data-active="true"]{
        opacity: 1;
    }

}